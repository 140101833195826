import styled, { css } from 'styled-components';

const determineHeaderHeight = ({ theme, isDashboardMode }) => {
  const headerHeight =
    theme[isDashboardMode ? 'dashboardHeaderHeight' : 'headerHeight'];
  return `calc(100vh - ${headerHeight})`;
};

const BodyContainer = styled.div`
  width: 100%;
  height: ${determineHeaderHeight};
  color: ${({ theme }) => theme.colors.darkGrey};
  display: grid;
  grid-template-columns: auto 1fr;
  ${({ isDashboardMode }) =>
    isDashboardMode &&
    css`
      margin-left: 0;
      grid-template-columns: 1fr;
      width: 100vw;
    `}
`;

const ContentContainer = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

export { BodyContainer, ContentContainer };
