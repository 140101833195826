import { generateLinkWithParams } from "@trackmatic/yard-ui-common";

const routing = {
  appLoginActivity: "/app_login_activity",
  knoxMobileData: "/knox_mobile_data",
};

const sideNav = {
  tm: {
    devices: {
      name: "Usage",
      sub: {
        appLoginActivity: {
          name: "App Login Activity",
          link: generateLinkWithParams(routing.appLoginActivity),
        },
        knoxMobileData: {
          name: "Samsung Knox Snapshot",
          link: generateLinkWithParams(routing.knoxMobileData),
        },
      },
    },
  },
};

export { sideNav, routing };
