import { Table, Td, Th } from "@trackmatic/yard-ui-common";
import React from "react";
import { ShapedAppData } from "../types";
import { TableContainer } from "./styles";

const TablePart: React.FC<{ data: ShapedAppData }> = ({ data }) => {
  const { deviceData } = data;
  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <Th>Device</Th>
            <Th>Make and Model</Th>
            <Th>OS Version</Th>
            <Th>Earliest</Th>
            <Th>Latest</Th>
            <Th>Org / Site / Contact</Th>
            <Th>Logins</Th>
          </tr>
        </thead>
        <tbody>
          {Object.values(deviceData).map((device) => {
            return (
              <tr key={device.name}>
                <Td>{device.name}</Td>
                <Td>{device.makeModel}</Td>
                <Td>{device.osVersion}</Td>
                <Td>{device.earliestLoginVersion}</Td>
                <Td>{device.latestLoginVersion}</Td>
                <td>
                  {Object.keys(device.orgSiteContacts).map((val) => (
                    <p key={val}>{val}</p>
                  ))}
                </td>
                <Td>{device.loginCount}</Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default TablePart;
