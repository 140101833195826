import { PageContainer, PageHeading } from '@trackmatic/yard-ui-common';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Filters, FiltersType } from '../../../common';
import { AppLoginActivity, TheseFilters } from '../types';
import AppLogins from './AppLogins';
import { getFilters, shapeData } from './functions';
import KPIs from './KPIs';
import MapPart from './MapPart';
import OrgLogins from './OrgLogins';
import { Container } from './styles';
import TablePart from './TablePart';

const initialValues: TheseFilters = {
  application: '',
  applicationVersion: '',
  org: '',
  clientName: '',
  imei: '',
};

const filterData = <
  Type extends { [x: string]: any },
  Filter extends { [x: string]: any },
>(
  data: Type[],
  filterObject: Filter,
) => {
  return data.filter((item) => {
    const checks = Object.keys(filterObject).map((key) =>
      filterObject[key] ? item[key] === filterObject[key] : true,
    );
    return checks.every(Boolean);
  });
};

const Dashboard: React.FC<{ data: AppLoginActivity[] }> = ({ data }) => {
  const [filterObject, setFilterObject] = useState<FiltersType>(initialValues);
  const cleanedData: AppLoginActivity[] = data.map((d) => {
    const application = capitalize(d.application?.split(' ')[0]);
    return {
      ...d,
      application,
      appAndVersion: `${application} ${d.applicationVersion}`,
      org: d.organisationNameFromClient || d.organisationNameFromContact,
    };
  });
  const filters = getFilters(cleanedData);
  const shapedData = shapeData(filterData(cleanedData, filterObject));
  const { loginCount, dateDisplay } = shapedData;
  return (
    <PageContainer style={{ padding: 0 }}>
      <PageHeading
        heading="App Login Activity"
        description={`Reporting over ${loginCount} logins ${dateDisplay}`}
        style={{}}
      />
      <Container>
        <KPIs data={shapedData} />
        <MapPart data={shapedData} />
        <TablePart data={shapedData} />
        <OrgLogins data={shapedData} />
        <AppLogins data={shapedData} />
        <Filters
          filters={filters}
          initialValues={initialValues}
          setFilterObject={setFilterObject}
        />
      </Container>
    </PageContainer>
  );
};

export default Dashboard;
