import { formatDateTime, Table, Th } from '@trackmatic/yard-ui-common';
import { noop } from 'lodash';
import styled from 'styled-components';
import { TableContainer } from './styles';
import { KnoxDataPropFC } from './types';
const Td = styled.td<{ isActive: boolean }>`
  font-size: 0.6rem;
  padding: 0 0.5rem;
  background: ${({ theme, isActive }) => isActive && theme.colors.primary};
  :hover {
    background: ${({ theme, isActive }) => !isActive && theme.colors.lightGrey};
  }
`;

const TableComponent: KnoxDataPropFC = ({
  devices = [],
  activeDeviceId,
  setActiveDeviceId = noop,
}) => {
  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <Th>IMEI</Th>
            <Th>Make</Th>
            <Th>Model</Th>
            <Th>OS</Th>
            <Th>CCID</Th>
            <Th>Organisation</Th>
            <Th>Latest Update</Th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device) => {
            const Tdd = ({ children }: any) => (
              <Td
                key={device.deviceId}
                isActive={activeDeviceId === device.deviceId}
                onClick={() => setActiveDeviceId(device.deviceId)}
              >
                {children}
              </Td>
            );
            return (
              <tr key={device.deviceId}>
                <Tdd>{device.imei}</Tdd>
                <Tdd>{device.deviceModelKind}</Tdd>
                <Tdd>{device.deviceModel}</Tdd>
                <Tdd>{device.mobileId}</Tdd>
                <Tdd>{device.iccid}</Tdd>
                <Tdd>{device.orgName}</Tdd>
                <Tdd>{formatDateTime(device.latestDate)}</Tdd>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
