import styled, { css } from "styled-components";

const MapPoint = styled.div<{ isActive?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background: rgba(65, 147, 169, 0.5);
  z-index: 4;
  transform: translate(-50%, -50%);
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  ${({ theme, isActive }) =>
    isActive &&
    css`
      width: 16px;
      height: 16px;
      background: ${theme.colors.warning};
      z-index: 5;
    `}
`;

export { MapPoint };
