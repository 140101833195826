import { PageError, PageLoading } from '@trackmatic/yard-ui-common';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import { KnoxSnapShot } from './types';

const useAxios = (axiosInstance = axios) => {
  const [error, setError] = useState<any | null>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<KnoxSnapShot[] | null>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        setData(null);
        const { data: axiosData } = await axiosInstance.get(
          'https://de1h8ho1gh.execute-api.eu-west-1.amazonaws.com/devices',
        );
        setData(axiosData);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [axiosInstance]);

  return { error, loading, data };
};

const SamsungKnoxSnapshot = () => {
  const { error, data } = useAxios();

  if (error) {
    return <PageError message={error.message} />;
  }
  if (data) {
    console.log('data', data);
    return <Dashboard devices={data} />;
  }
  return <PageLoading />;
};

export default SamsungKnoxSnapshot;
