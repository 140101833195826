import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { GoogleMap } from '@react-google-maps/api';
import {
  googleMapsOptions,
  googleMapsStyles,
  MapCenterContext,
  PageError,
  PageLoading,
  theme,
  useBoundMap,
  useGoogleMapsLoader,
} from '@trackmatic/yard-ui-common';
import { useContext, useState } from 'react';
import { AppDataPropFC, AppLoginActivity } from '../types';
import { ComponentContainer, MapContainer } from './styles';

const MapPart: AppDataPropFC = ({ data: { logins } }) => {
  const { loadError, isLoaded } = useGoogleMapsLoader();
  const { center } = useContext(MapCenterContext);
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const [markerCluster, setMarkerCluster] = useState<MarkerClusterer>();
  const [hoveredLogin, setHoveredLogin] = useState<AppLoginActivity>();

  const goodLogins = logins.filter(
    (login) => login.point.lat && login.point.lng,
  );

  useBoundMap(map, goodLogins.map((login) => login.point) as never[]);

  if (loadError) {
    return <PageError message={loadError.message} />;
  }

  if (isLoaded) {
    const svgMarker = {
      path: 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
      fillColor: theme.colors.primary,
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 30),
    };
    const markers = goodLogins.map((gl) => {
      const marker = new google.maps.Marker({
        position: gl.point,
        icon: svgMarker,
      });
      marker.addListener('click', () => {
        setHoveredLogin(gl);
      });
      return marker;
    });
    markerCluster?.clearMarkers();
    markerCluster?.addMarkers(markers);

    return (
      <ComponentContainer heading="Login Map" gridArea="map">
        <MapContainer>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={center}
            onLoad={(m) => {
              setMap(m);
              setMarkerCluster(new MarkerClusterer({ map: m, markers }));
            }}
            options={{
              ...googleMapsOptions,
              styles: googleMapsStyles.grayscale,
            }}
            zoom={4}
          />
          {hoveredLogin ? (
            <div>
              {Object.keys(hoveredLogin).map((key) => {
                return (
                  <div key={key}>
                    <strong>{key}</strong>:{' '}
                    {JSON.stringify((hoveredLogin as any)[key] || '')}
                  </div>
                );
              })}
            </div>
          ) : (
            <div>Click on a point to show details</div>
          )}
        </MapContainer>
      </ComponentContainer>
    );
  }

  return <PageLoading />;
};

export default MapPart;
