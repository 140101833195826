import { useQuery } from '@apollo/client';
import {
  getDefaultDateRange,
  PageError,
  PageLoading,
} from '@trackmatic/yard-ui-common';
import Dashboard from './Dashboard';
import { GET_LOGINS } from './gql';
import { AppLoginActivity as ALType } from './types';

const AppLoginActivity = () => {
  const { data, error } = useQuery<{ getAppLoginActivity: ALType[] }>(
    GET_LOGINS,
    {
      variables: { dateRange: getDefaultDateRange() },
    },
  );

  if (error) {
    return <PageError message={error.message} />;
  }

  if (data) {
    return <Dashboard data={data.getAppLoginActivity} />;
  }

  return <PageLoading />;
};

export default AppLoginActivity;
