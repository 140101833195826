import {
  formatDateTime,
  formatNumber,
  PageHeading,
} from '@trackmatic/yard-ui-common';
import React, { useState } from 'react';
import { Filters, FiltersType } from '../../common';
import { filterData, getFilters, shapeData } from './functions';
import MapComponent from './MapComponent';
import { Container, DashboardContainer } from './styles';
import TableComponent from './TableComponent';
import { KnoxFilters, KnoxSnapShot } from './types';

const initialValues: KnoxFilters = {
  org: '',
  make: '',
  model: '',
};

const Dashboard: React.FC<{ devices: KnoxSnapShot[] }> = ({ devices }) => {
  const [activeDeviceId, setActiveDeviceId] = useState('');
  const [filterObject, setFilterObject] = useState<FiltersType>(initialValues);
  const filters = getFilters(devices);
  const { shapedDevices, deviceCount } = shapeData(
    filterData(devices, filterObject),
  );

  return (
    <Container>
      <PageHeading
        heading="Samsung Knox Snapshot"
        description={`Showing Data for ${formatNumber(
          deviceCount,
        )} Enrolled Devices as at ${formatDateTime(new Date())}`}
        style={{}}
      />
      <DashboardContainer>
        <MapComponent
          devices={shapedDevices.filter((d) => d.hasPosition)}
          activeDeviceId={activeDeviceId}
        />
        <TableComponent
          devices={shapedDevices}
          activeDeviceId={activeDeviceId}
          setActiveDeviceId={setActiveDeviceId}
        />
        <Filters
          filters={filters}
          initialValues={initialValues}
          setFilterObject={setFilterObject}
        />
      </DashboardContainer>
    </Container>
  );
};

export default Dashboard;
