import { formatNumber, formatPercent, theme } from "@trackmatic/yard-ui-common";
import { AppDataPropFC } from "../types";
import {
  BarContainer,
  ComponentContainer,
  SuccessContainer,
  SuccessData,
  SuccessRow,
} from "./styles";

const OrgLogins: AppDataPropFC = ({
  data: { orgData, maxOrgDeviceCount, maxOrgLoginPerDeviceCount },
}) => {
  return (
    <ComponentContainer gridArea="success" heading="Devices per Org">
      <SuccessContainer>
        <SuccessData>
          {orgData.map((org) => {
            const dcPercent = formatPercent(org.deviceCount, maxOrgDeviceCount);
            const lPercent = formatPercent(
              org.loginsPerDevice,
              maxOrgLoginPerDeviceCount
            );
            return (
              <SuccessRow>
                <div>{org.name}</div>
                <BarContainer width={dcPercent} color={theme.colors.primary}>
                  <div />
                  <p>{formatNumber(org.deviceCount)}</p>
                </BarContainer>
                <BarContainer width={lPercent} color={theme.colors.secondary}>
                  <div />
                  <p>{formatNumber(org.loginsPerDevice?.toFixed(2))}</p>
                </BarContainer>
              </SuccessRow>
            );
          })}
        </SuccessData>
        <SuccessRow>
          <div />
          <div>Devices</div>
          <div>Logins per Device</div>
        </SuccessRow>
      </SuccessContainer>
    </ComponentContainer>
  );
};

export default OrgLogins;
