import { lighten } from 'polished';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr 1fr;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  grid-template-areas:
    'kpi kpi kpi filters'
    'map success apps filters'
    'table table table filters';
  grid-gap: 1rem;
`;

const KPIContainer = styled.div`
  grid-area: kpi;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
`;

const KPISingle = styled.div`
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  & h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 3.5rem;
    font-weight: bold;
  }
  & h3 {
    font-size: 0.8rem;
  }
  :nth-child(even) {
    background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  }
`;

const Base = styled.div`
  font-size: 0.6rem;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0.5rem;
  > h3 {
    text-align: center;
    font-size: 1.5rem;
  }
`;

const ComponentContainer: React.FC<
  PropsWithChildren<{ heading: string; gridArea: string }>
> = ({ heading, children, gridArea }) => {
  return (
    <Base style={{ gridArea }}>
      <h3>{heading}</h3>
      {children}
    </Base>
  );
};

const BarContainer = styled.div<{ width: number; color: string }>`
  display: grid !important;
  grid-template-columns: ${({ width }) => `${width}% auto`};
  align-items: center;
  width: 75%;
  height: 100%;
  padding: 0 !important;
  > div {
    width: 100%;
    height: 90%;
    background: ${({ color }) => color};
  }
  > p {
    padding-left: 3px;
    font-weight: bold;
    font-size: 0.5rem;
  }
`;

const MapContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  > div:last-child {
    padding: 0.4rem;
  }
`;

const SuccessContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const SuccessData = styled.div`
  height: 30vh;
  overflow: auto;
`;

const SuccessRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  :nth-child(even) {
    background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  }
  padding: 0.1rem 0;
`;

const TableContainer = styled.div`
  grid-area: table;
  height: 30vh;
  overflow: auto;
  & * {
    font-size: 0.6rem !important;
  }
`;
const LatestContainer = styled.div`
  grid-area: latest;
`;

const NewContainer = styled.div`
  grid-area: new;
`;

const MapTooltip = styled.div`
  padding: 0.3rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
`;

export {
  Container,
  KPIContainer,
  LatestContainer,
  MapContainer,
  NewContainer,
  MapTooltip,
  SuccessContainer,
  TableContainer,
  KPISingle,
  SuccessData,
  SuccessRow,
  BarContainer,
  ComponentContainer,
};
