import styled from 'styled-components';
import { OnClickLink, EditLink } from '@trackmatic/yard-ui-common';
import {
  ChevronRight16,
  OverflowMenuVertical16 as VerticalOverFlow,
} from '@carbon/icons-react';
import { lighten, darken } from 'polished';

const HeaderContainer = styled.div`
  width: 100%;
  height: ${({ theme, isDashboardMode }) =>
    theme[isDashboardMode ? 'dashboardHeaderHeight' : 'headerHeight']};
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  padding: 0 0.2rem 0 0;
  align-items: center;
  grid-template-columns: auto auto auto auto 1fr;
  font-size: 0.7rem;
`;
const Logo = styled.img`
  height: ${({ theme, isDashboardMode }) =>
    `${
      parseInt(
        theme[
          isDashboardMode ? 'dashboardHeaderHeight' : 'headerHeight'
        ].replace('px', ''),
      ) - 15
    }px`};
`;

const Logout = styled(OnClickLink)`
  justify-self: flex-end;
  font-size: 0.9rem;
  :hover {
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
  }
`;

const LogoutModalContainer = styled.div`
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const BreadCrumbsContainer = styled.div`
  display: flex;
`;

const BreadCrumbLink = styled(EditLink)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
const BreadCrumbNoLink = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const BreadCrumbsSeparator = styled(ChevronRight16)`
  margin: 0 1rem;
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const MinimalMenuBurger = styled.div`
  height: 100%;
  width: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    > div {
      display: flex;
    }
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const VerticalMenu = styled(VerticalOverFlow)`
  & circle {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const MinimalMenuComponent = styled.div`
  box-shadow: 0px 3px 8px ${({ theme }) => theme.colors.mediumGrey};
  top: 20px;
  position: fixed;
  left: 15px;
  transition: 100ms;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  z-index: 5000000000;
  display: none;
  flex-direction: column;
  & a {
    text-decoration: none;
    padding: 10px;
    color: ${({ theme }) => darken(0.1, theme.colors.darkGrey)};
    :hover {
      display: flex;
      background: ${({ theme }) => theme.colors.lightGrey};
    }
    :not(:last-child) {
      border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
    }
  }
`;

export {
  HeaderContainer,
  Logo,
  Logout,
  LogoutModalContainer,
  BreadCrumbLink,
  BreadCrumbNoLink,
  BreadCrumbsContainer,
  BreadCrumbsSeparator,
  MinimalMenuBurger,
  VerticalMenu,
  MinimalMenuComponent,
};
