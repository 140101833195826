import {
  InlineNotification,
  NotificationsContext,
  SideNav,
  DashboardModeContext,
} from '@trackmatic/yard-ui-common';
import React, { useContext } from 'react';
import { LoggedInUserContext } from 'src/common';
import { BodyContainer, ContentContainer } from './styles';

const Body = ({ children }) => {
  const { linkParams, currentEntity } = useContext(LoggedInUserContext);
  const { inlineAlert } = useContext(NotificationsContext);
  const { isDashboardMode } = useContext(DashboardModeContext);
  return (
    <BodyContainer isDashboardMode={isDashboardMode}>
      {!isDashboardMode && (
        <SideNav
          linkParams={linkParams}
          showLogo={currentEntity && currentEntity.logo}
        />
      )}
      <ContentContainer>
        {inlineAlert && (
          <InlineNotification
            kind={inlineAlert.type}
            title={inlineAlert.title}
            subtitle={inlineAlert.message}
          />
        )}
        {children}
      </ContentContainer>
    </BodyContainer>
  );
};

export default Body;
