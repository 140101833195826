import { startCase } from "lodash";
import { Fragment, useContext } from "react";
import { useLocation } from "react-router-dom";
import { LoggedInUserContext } from "src/common";
import {
  BreadCrumbLink,
  BreadCrumbNoLink,
  BreadCrumbsContainer,
  BreadCrumbsSeparator,
} from "./styles";

const BreadCrumbs = () => {
  const location = useLocation();
  const { currentUser } = useContext(LoggedInUserContext);
  const splitPaths = location.pathname.split("/");
  const crumbs = splitPaths.map((p, idx) => {
    if (idx > 0 && idx <= 4) {
      const Component =
        idx < 4
          ? currentUser.isTMUser
            ? BreadCrumbLink
            : BreadCrumbNoLink
          : BreadCrumbLink;

      const link = splitPaths.slice(0, idx + 1).join("/");

      const def = startCase(p);
      const display = def;
      return (
        <Fragment>
          <Component to={link} key={idx}>
            {display}
          </Component>
          {idx !== splitPaths.length - 1 && <BreadCrumbsSeparator />}
        </Fragment>
      );
    }
    return null;
  });
  return <BreadCrumbsContainer>{crumbs}</BreadCrumbsContainer>;
};

export default BreadCrumbs;
