import { PageError, SideNavContext } from '@trackmatic/yard-ui-common';
import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoggedInUserContext, routing, sideNav } from 'src/common';
import AppLoginActivity from './modules/AppLoginActivity';
import SamsungKnoxSnapshot from './modules/SamsungKnoxSnapshot';

const Routes = () => {
  const { currentUser } = useContext(LoggedInUserContext);
  const { setSideNavOptions } = useContext(SideNavContext);
  setSideNavOptions(sideNav.tm);

  if (!currentUser.isTMUser) {
    return <PageError message="You do not have access to this module" />;
  }

  return (
    <Switch>
      <Route path={routing.appLoginActivity} component={AppLoginActivity} />
      <Route path={routing.knoxMobileData} component={SamsungKnoxSnapshot} />
    </Switch>
  );
};

export default Routes;
