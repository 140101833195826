import {
  clearAllLocalStorageKeys,
  PrimaryButton,
  SecondaryButton,
} from '@trackmatic/yard-ui-common';
import React from 'react';
import { LogoutModalContainer } from './styles';

const LogoutModal = ({ close }) => {
  return (
    <LogoutModalContainer>
      <h1>Logout</h1>
      <h3>Are you sure?</h3>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridColumnGap: '0.5rem',
        }}
      >
        <PrimaryButton
          style={{ width: '200px' }}
          onClick={() => {
            clearAllLocalStorageKeys();
            window.location.href = '/';
          }}
        >
          Logout
        </PrimaryButton>
        <SecondaryButton style={{ width: '200px' }} onClick={close}>
          Cancel
        </SecondaryButton>
      </div>
    </LogoutModalContainer>
  );
};

export default LogoutModal;
