import { GoogleMap, OverlayView } from '@react-google-maps/api';
import {
  formatDateTime,
  googleMapsOptions,
  googleMapsStyles,
  MapCenterContext,
  PageError,
  PageLoading,
  useBoundMap,
  useGoogleMapsLoader,
} from '@trackmatic/yard-ui-common';
import Tooltip from 'rc-tooltip';
import { useContext, useState } from 'react';
import { MapPoint } from './styles';
import { KnoxDataPropFC } from './types';
const MapComponent: KnoxDataPropFC = ({ devices = [], activeDeviceId }) => {
  const { loadError, isLoaded } = useGoogleMapsLoader();
  const [map, setMap] = useState();
  const { center } = useContext(MapCenterContext);

  useBoundMap(map, devices.map(({ position }) => position) as never[]);

  if (loadError) {
    return <PageError message={loadError.message} />;
  }
  if (isLoaded) {
    return (
      <GoogleMap
        mapContainerStyle={{ gridArea: 'map' }}
        onLoad={(m: any) => setMap(m)}
        options={{ ...googleMapsOptions, styles: googleMapsStyles.grayscale }}
        center={center}
        zoom={10}
      >
        {devices.map((device) => {
          const overlay = (
            <OverlayView
              key={device.deviceId}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={device.position}
            >
              <Tooltip
                destroyTooltipOnHide
                overlay={
                  <div>
                    <h3>{device.imei}</h3>
                    <h4>{device.deviceModelKind}</h4>
                    <div>Org: {device.orgName}</div>
                    <div>Last Update: {formatDateTime(device.latestDate)}</div>
                  </div>
                }
              >
                <MapPoint isActive={false} />
              </Tooltip>
            </OverlayView>
          );
          if (activeDeviceId) {
            return device.deviceId === activeDeviceId ? overlay : null;
          }
          return overlay;
        })}
      </GoogleMap>
    );
  }

  return <PageLoading />;
};

export default MapComponent;
