import { formatNumber, formatPercent, theme } from '@trackmatic/yard-ui-common';
import { AppDataPropFC } from '../types';
import {
  BarContainer,
  ComponentContainer,
  SuccessContainer,
  SuccessData,
  SuccessRow,
} from './styles';

const AppLogins: AppDataPropFC = ({
  data: { appData, maxAppDeviceCount, maxAppLoginPerDeviceCount },
}) => {
  return (
    <ComponentContainer gridArea="apps" heading="Devices per App">
      <SuccessContainer>
        <SuccessData>
          {appData.map((app) => {
            const dcPercent = formatPercent(app.deviceCount, maxAppDeviceCount);
            const lPercent = formatPercent(
              app.loginsPerDevice,
              maxAppLoginPerDeviceCount,
            );
            return (
              <SuccessRow>
                <div>{app.name}</div>
                <BarContainer width={dcPercent} color={theme.colors.primary}>
                  <div />
                  <p>{formatNumber(app.deviceCount)}</p>
                </BarContainer>
                <BarContainer width={lPercent} color={theme.colors.secondary}>
                  <div />
                  <p>{formatNumber(app.loginsPerDevice?.toFixed(2))}</p>
                </BarContainer>
              </SuccessRow>
            );
          })}
        </SuccessData>
        <SuccessRow>
          <div />
          <div>Devices</div>
          <div>Logins per Device</div>
        </SuccessRow>
      </SuccessContainer>
    </ComponentContainer>
  );
};

export default AppLogins;
