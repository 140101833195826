import { gql } from '@apollo/client';

const GET_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      email
      isTMUser
    }
  }
`;

export { GET_USER };
