import { formatNumber } from '@trackmatic/yard-ui-common';
import { AppDataPropFC } from '../types';
import { KPIContainer, KPISingle } from './styles';

interface KPIProps {
  kpi: any;
  subtitle: string;
}

const KPI: React.FC<KPIProps> = ({ kpi, subtitle }) => {
  return (
    <KPISingle>
      <h2>{kpi}</h2>
      <h3>{subtitle}</h3>
    </KPISingle>
  );
};

const KPIs: AppDataPropFC = ({
  data: { loginCount, deviceCount, loginsPerDevice, userCount },
}) => {
  return (
    <KPIContainer>
      <KPI kpi={formatNumber(loginCount)} subtitle="Logins" />
      <KPI kpi={formatNumber(deviceCount)} subtitle="Devices" />
      <KPI
        kpi={formatNumber(loginsPerDevice.toFixed(2))}
        subtitle="Logins per Device"
      />
      <KPI kpi={formatNumber(userCount)} subtitle="Users" />
    </KPIContainer>
  );
};

export default KPIs;
