import { get, orderBy } from 'lodash';
import { FilterSchema } from '../../common';
import { FilterOption, KnoxSnapShot, ShapedKnoxData } from './types';

interface FilterObject {
  [x: string]: FilterOption;
}
interface ShapedData {
  shapedDevices: ShapedKnoxData[];
  deviceCount: number;
}

const makeOption = (value: string) => ({ value, label: value });

const transformFilter = (filter: FilterObject) =>
  orderBy(Object.values(filter), [(f) => f.value?.toLowerCase()], ['asc']);

const getFilters = (data: KnoxSnapShot[]): FilterSchema[] => {
  const [orgs, makes, models]: [FilterObject, FilterObject, FilterObject] = [
    {},
    {},
    {},
  ];
  for (const device of data) {
    models[device.deviceModel] = makeOption(device.deviceModel);
    orgs[device.orgName] = makeOption(device.orgName);
    makes[device.deviceModelKind] = makeOption(device.deviceModelKind);
  }
  return [
    {
      name: 'orgName',
      options: transformFilter(orgs),
      labelText: 'Organisation',
    },
    {
      name: 'deviceModelKind',
      options: transformFilter(makes),
      labelText: 'Make',
    },
    {
      name: 'deviceModel',
      options: transformFilter(models),
      labelText: 'Model',
    },
  ];
};
const shapeData = (devices: KnoxSnapShot[]): ShapedData => {
  let deviceCount = 0;
  const shapedDevices = orderBy(
    devices.map((device: KnoxSnapShot) => {
      deviceCount++;

      const [lat, lng, latestDate] = [
        'location.latitude',
        'location.longitude',
        'location.updated',
      ].map((key) => get(device, key));
      return {
        deviceId: device.deviceId,
        imei: device.imei,
        deviceModelKind: device.deviceModelKind,
        deviceModel: device.deviceModel,
        mobileId: device.mobileId,
        iccid: device.iccid,
        orgName: device.orgName,
        hasPosition: !!lat && !!lng,
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
        latestDate,
      };
    }),
    ['latestDate', 'imei'],
    ['desc', 'asc'],
  );

  return {
    shapedDevices,
    deviceCount,
  };
};

const filterData = <
  Type extends { [x: string]: any },
  Filter extends { [x: string]: any },
>(
  data: Type[],
  filterObject: Filter,
) => {
  return data.filter((item) => {
    const checks = Object.keys(filterObject).map((key) =>
      filterObject[key] ? item[key] === filterObject[key] : true,
    );
    return checks.every(Boolean);
  });
};

export { getFilters, shapeData, filterData };
