import {
  DividerLine,
  ModalContext,
  trackmaticLogo,
  marker,
  DashboardModeContext,
  theme,
} from '@trackmatic/yard-ui-common';
import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { LoggedInUserContext } from 'src/common';
import BreadCrumbs from './BreadCrumbs';
import LogoutModal from './LogoutModal';
import {
  HeaderContainer,
  Logo,
  Logout,
  MinimalMenuBurger,
  VerticalMenu,
  MinimalMenuComponent,
} from './styles';

const Header = () => {
  const { currentEntity } = useContext(LoggedInUserContext);
  const { setModalContent, toggleModal } = useContext(ModalContext);
  const [closeMenu, setCloseMenu] = useState(false);
  const { dashboardMenuItems, isDashboardMode } =
    useContext(DashboardModeContext);

  useEffect(() => {
    if (closeMenu) {
      const myTimer = setTimeout(() => {
        setCloseMenu(false);
      }, 10);
      return () => {
        clearTimeout(myTimer);
      };
    }
  }, [closeMenu]);
  return (
    <HeaderContainer isDashboardMode={isDashboardMode}>
      <Link
        to="/"
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '15rem',
          background: currentEntity && currentEntity.secondaryColor,
        }}
      >
        <Logo
          isDashboardMode={isDashboardMode}
          src={
            isDashboardMode
              ? marker
              : (currentEntity && currentEntity.logo) || trackmaticLogo
          }
        />
      </Link>
      {dashboardMenuItems && dashboardMenuItems.length > 0 ? (
        <MinimalMenuBurger>
          {!closeMenu && (
            <MinimalMenuComponent>
              {dashboardMenuItems.map((item) => (
                <NavLink
                  exact
                  key={item.link}
                  to={item.link}
                  onClick={() => setCloseMenu(true)}
                  activeStyle={{ background: theme.colors.lightGrey }}
                >
                  {item.name}
                </NavLink>
              ))}
            </MinimalMenuComponent>
          )}

          <VerticalMenu />
        </MinimalMenuBurger>
      ) : (
        <div />
      )}
      <DividerLine />
      <BreadCrumbs />
      <Logout
        style={{ justifySelf: 'flex-end' }}
        onClick={() => {
          toggleModal();
          setModalContent(<LogoutModal close={toggleModal} />);
        }}
      >
        Logout
      </Logout>
    </HeaderContainer>
  );
};

export default Header;
