import {
  ApolloProvider,
  DashboardModeProvider,
  GoogleMapsLoaderProvider,
  initiateSentry,
  LoadingProvider,
  LoginProcessProvider,
  MapCenterProvider,
  ModalProvider,
  NotificationsProvider,
  SentryErrorBoundary,
  setDocumentTitle,
  SideNavProvider,
  ThemeProvider,
} from "@trackmatic/yard-ui-common";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { LoggedInUserProvider } from "src/common";
import { Body, Header } from "./containers";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./Routes";

initiateSentry();
setDocumentTitle("Insights Portal");

ReactDOM.render(
  <React.StrictMode>
    <SentryErrorBoundary>
      <ApolloProvider>
        <ThemeProvider>
          <Router>
            <LoadingProvider>
              <NotificationsProvider>
                <LoginProcessProvider>
                  <LoggedInUserProvider>
                    <GoogleMapsLoaderProvider>
                      <ModalProvider>
                        <MapCenterProvider>
                          <DashboardModeProvider>
                            <SideNavProvider>
                              <Header />
                              <Body>
                                <Routes />
                              </Body>
                            </SideNavProvider>
                          </DashboardModeProvider>
                        </MapCenterProvider>
                      </ModalProvider>
                    </GoogleMapsLoaderProvider>
                  </LoggedInUserProvider>
                </LoginProcessProvider>
              </NotificationsProvider>
            </LoadingProvider>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </SentryErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console..log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
