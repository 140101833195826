import { formatDateTime } from '@trackmatic/yard-ui-common';
import { orderBy } from 'lodash';
import {
  AppLoginActivity,
  DeviceMetricBase,
  DeviceMetricObject,
  FilterOption,
  FilterSchema,
  MetricBase,
  MetricBaseExtended,
  MetricObject,
  ShapedAppData,
} from '../types';

const handleMetricObject = (
  metricObject: MetricObject,
  key: string,
  loginId: string,
) => {
  if (!key) return;
  if (metricObject[key]) {
    metricObject[key].loginObject[loginId] = loginId;
  } else {
    metricObject[key] = {
      name: key,
      loginObject: { [loginId]: loginId },
    };
  }
};

interface FilterObject {
  [x: string]: FilterOption;
}

const makeOption = (value: string) => ({ value, label: value });

const transformFilter = (filter: FilterObject) =>
  orderBy(Object.values(filter), [(f) => f.value?.toLowerCase()], ['asc']);

const getFilters = (data: AppLoginActivity[]): FilterSchema[] => {
  const [applications, versions, orgs, sites, imeis]: [
    FilterObject,
    FilterObject,
    FilterObject,
    FilterObject,
    FilterObject,
  ] = [{}, {}, {}, {}, {}];
  for (const login of data) {
    applications[login.application] = makeOption(login.application);
    versions[login.applicationVersion] = makeOption(login.applicationVersion);
    orgs[login.org] = makeOption(login.org);
    sites[login.clientName] = makeOption(login.clientName);
    imeis[login.imei] = makeOption(login.imei);
  }
  return [
    {
      name: 'application',
      options: transformFilter(applications),
      labelText: 'Application',
    },
    {
      name: 'applicationVersion',
      options: transformFilter(versions),
      labelText: 'Version',
    },
    {
      name: 'org',
      options: transformFilter(orgs),
      labelText: 'Organisation',
    },
    {
      name: 'clientName',
      options: transformFilter(sites),
      labelText: 'Site',
    },
    {
      name: 'imei',
      options: transformFilter(imeis),
      labelText: 'IMEI',
    },
  ];
};

const shapeData = (data: AppLoginActivity[]): ShapedAppData => {
  let [
    prevDate,
    minDate,
    maxDate,
    loginObject,
    appObject,
    deviceObject,
    userObject,
    orgObject,
    maxOrgDeviceCount,
    maxOrgLoginPerDeviceCount,
    maxAppDeviceCount,
    maxAppLoginPerDeviceCount,
  ]: [
    Date,
    Date,
    Date,
    MetricObject,
    MetricObject,
    DeviceMetricObject,
    MetricObject,
    MetricObject,
    number,
    number,
    number,
    number,
  ] = [
    new Date('1900-01-01'),
    new Date('3000-01-01'),
    new Date('1900-01-01'),
    {},
    {},
    {},
    {},
    {},
    0,
    0,
    0,
    0,
  ];
  const logins = data.map((login): AppLoginActivity => {
    const {
      id,
      imei,
      username,
      timeOfOccurrence,
      org,
      appAndVersion: app,
      deviceManufacturer,
      deviceModel,
      osVersion,
      clientName,
      contactName,
    } = login;

    const getInitialLoginObject = () => ({ [id]: id });

    const orgSite = `${org || ''} ${clientName || ''}`.trim();

    const makeModel = `${deviceManufacturer || ''} ${deviceModel || ''}`.trim();

    //handle org
    if (org) {
      if (orgObject[org]) {
        orgObject[org].loginObject[id] = id;
        const { deviceObject: orgDeviceObject } = orgObject[org];
        if (orgDeviceObject) {
          handleMetricObject(orgDeviceObject, imei, id);
        }
      } else {
        orgObject[org] = {
          name: org,
          loginObject: getInitialLoginObject(),
          deviceObject: {
            [imei]: {
              name: imei,
              loginObject: getInitialLoginObject(),
            },
          },
        };
      }
    }

    //handle appV
    if (appObject[app]) {
      appObject[app].loginObject[id] = id;
      const { deviceObject: appDeviceObject } = appObject[app];
      if (appDeviceObject) {
        handleMetricObject(appDeviceObject, imei, id);
      }
    } else {
      appObject[app] = {
        name: app,
        loginObject: getInitialLoginObject(),
        deviceObject: {
          [imei]: {
            name: imei,
            loginObject: getInitialLoginObject(),
          },
        },
      };
    }

    const date = new Date(timeOfOccurrence);

    //handle device
    const orgSiteContact = `${orgSite || ''} - ${contactName || ''}`.trim();
    if (deviceObject[imei]) {
      deviceObject[imei].loginObject[id] = id;
      if (orgSiteContact) {
        deviceObject[imei].orgSiteContacts[orgSiteContact] = orgSiteContact;
      }
      if (date < prevDate) {
        deviceObject[imei].earliestLoginVersion = app;
      } else {
        deviceObject[imei].latestLoginVersion = app;
      }
    } else {
      deviceObject[imei] = {
        name: imei,
        loginObject: getInitialLoginObject(),
        makeModel,
        osVersion,
        earliestLoginVersion: app,
        latestLoginVersion: app,
        orgSiteContacts: orgSiteContact
          ? { [orgSiteContact]: orgSiteContact }
          : {},
      };
    }

    prevDate = date;

    minDate = date < minDate ? date : minDate;
    maxDate = date > maxDate ? date : maxDate;

    handleMetricObject(loginObject, id, id);
    handleMetricObject(userObject, username, id);

    return { ...login, point: { lat: login.latitude, lng: login.longitude } };
  });

  const loginCount = Object.keys(loginObject).length;
  const deviceCount = Object.keys(deviceObject).length;

  const userCount = Object.keys(userObject).length;
  const loginsPerDevice = loginCount / deviceCount;

  const [minDateOnly, maxDateOnly] = [minDate, maxDate].map((date) =>
    formatDateTime(date, undefined, false),
  );

  const dateDisplay =
    minDateOnly === maxDateOnly
      ? `for ${minDateOnly}`
      : `from ${minDateOnly} to ${maxDateOnly}`;

  const handleLoginCount = (
    obj: MetricBase | MetricBaseExtended | DeviceMetricBase,
  ) => {
    obj.loginCount = Object.keys(obj.loginObject).length;
  };

  Object.values(orgObject).forEach((obj) => {
    handleLoginCount(obj);
    obj.deviceCount = Object.keys(obj.deviceObject!).length;
    obj.loginsPerDevice = obj.loginCount! / obj.deviceCount;

    maxOrgDeviceCount =
      obj.deviceCount >= maxOrgDeviceCount
        ? obj.deviceCount
        : maxOrgDeviceCount;
    maxOrgLoginPerDeviceCount =
      obj.loginsPerDevice >= maxOrgLoginPerDeviceCount
        ? obj.loginsPerDevice
        : maxOrgLoginPerDeviceCount;
  });

  Object.values(appObject).forEach((obj) => {
    handleLoginCount(obj);
    obj.deviceCount = Object.keys(obj.deviceObject!).length;
    obj.loginsPerDevice = obj.loginCount! / obj.deviceCount;
    maxAppDeviceCount =
      obj.deviceCount >= maxAppDeviceCount
        ? obj.deviceCount
        : maxAppDeviceCount;
    maxAppLoginPerDeviceCount =
      obj.loginsPerDevice >= maxAppLoginPerDeviceCount
        ? obj.loginsPerDevice
        : maxAppLoginPerDeviceCount;
  });

  Object.values(deviceObject).forEach(handleLoginCount);

  return {
    dateDisplay,
    deviceCount,
    deviceData: deviceObject,
    loginCount,
    logins,
    loginsPerDevice,
    maxAppDeviceCount,
    maxAppLoginPerDeviceCount,
    maxOrgDeviceCount,
    maxOrgLoginPerDeviceCount,
    userCount,
    orgData: orderBy(
      Object.values(orgObject),
      [(o) => o.name.toLowerCase()],
      ['asc'],
    ),
    appData: orderBy(
      Object.values(appObject),
      [(o) => o.name.toLowerCase()],
      ['asc'],
    ),
  };
};

const getBarPercent = (percent: number) => Math.max(percent - 30, 0);

export { shapeData, getBarPercent, getFilters };
