import {
  ActionButtons,
  Dropdown,
  FormItem,
  FormWrapper,
} from '@trackmatic/yard-ui-common';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import { FilterPropFC, FiltersType } from './types';

const FiltersContainer = styled.div`
  grid-area: filters;
`;

const Filters: FilterPropFC<FiltersType> = ({
  initialValues,
  filters,
  setFilterObject,
}) => {
  return (
    <FiltersContainer>
      <Formik initialValues={initialValues} onSubmit={setFilterObject}>
        <FormWrapper style={{ width: '100%' }}>
          <Form>
            <div>
              <FormItem>
                <h3>Filters</h3>
              </FormItem>
              {filters.map((filter) => {
                return (
                  <FormItem key={filter.name}>
                    <Field {...filter} component={Dropdown} />
                  </FormItem>
                );
              })}
            </div>
          </Form>
          <ActionButtons
            type="FinalStep"
            fillWidth
            primaryName="Apply"
            onClickPrimary={undefined}
            to={undefined}
            disabled={undefined}
            className={undefined}
            loading={undefined}
            customValidation={undefined}
            ignoreDirty
          />
        </FormWrapper>
      </Formik>
    </FiltersContainer>
  );
};

export { Filters };
