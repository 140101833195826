import { gql } from '@apollo/client';

const GET_LOGINS = gql`
  query getLogins($dateRange: DateRange!) {
    getAppLoginActivity(dateRange: $dateRange) {
      id
      imei
      timeOfOccurrence
      sim
      ipAddress
      application
      applicationVersion
      username
      osVersion
      deviceManufacturer
      deviceModel
      deviceSerial
      loginStatus
      errorMessage
      contactId
      contactName
      clientName
      organisationNameFromContact
      organisationNameFromClient
      timezoneOffset
      latitude
      longitude
    }
  }
`;

export { GET_LOGINS };
