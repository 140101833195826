import { useQuery } from "@apollo/client";
import { PageError, PageLoading } from "@trackmatic/yard-ui-common";
import { createContext, useState } from "react";
import { GET_USER } from "./gql";

const LoginContext = createContext();

const LoginProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();

  const { error } = useQuery(GET_USER, {
    onCompleted: ({ getCurrentUser: user }) => {
      setCurrentUser(user);
    },
  });

  if (error) {
    return <PageError message={error.message} />;
  }

  if (currentUser) {
    const linkParams = {};
    return (
      <LoginContext.Provider
        value={{
          currentUser,
          linkParams,
        }}
      >
        {children}
      </LoginContext.Provider>
    );
  }

  return <PageLoading fillScreen />;
};

export { LoginContext, LoginProvider };
