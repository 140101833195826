import { OverflowContainer } from '@trackmatic/yard-ui-common';
import styled, { css } from 'styled-components';

const Container = styled(OverflowContainer)`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
`;

const DashboardContainer = styled(OverflowContainer)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 2fr 6fr 1fr;
  grid-template-areas:
    'map table filters'
    'applist table filters';
  grid-gap: 0.5rem;
`;

const MapPoint = styled.div<{ isActive: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background: rgba(65, 147, 169, 0.5);
  z-index: 4;
  transform: translate(-50%, -50%);
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  ${({ theme, isActive }) =>
    isActive &&
    css`
      width: 16px;
      height: 16px;
      background: ${theme.colors.warning};
      z-index: 5;
    `}
`;

const TableContainer = styled.div`
  grid-area: table;
  overflow: auto;
`;

export { Container, DashboardContainer, MapPoint, TableContainer };
